<template>
  <div class="slider">
    <div class="fresh-slick">
      <VueSlickCarousel v-bind="settings">
        <div class="fresh-slick__item">
          <img src="../../assets/ou7-img/makro.png" />
        </div>
        <div class="fresh-slick__item">
          <img src="../../assets/ou7-img/carrefour.png" />
        </div>
        <div class="fresh-slick__item">
          <img src="../../assets/ou7-img/korzinka.png" />
        </div>
        <div class="fresh-slick__item">
          <img src="../../assets/ou7-img/havas.png" />
        </div>
        <div class="fresh-slick__item">
              <img src="../../assets/ou7-img/asiauz.png" />
        </div>
        <div class="fresh-slick__item">
              <img src="../../assets/ou7-img/magnum.jpg" />
       </div>
      </VueSlickCarousel>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  components: { VueSlickCarousel },
  data() {
    return {
      settings: {
        centerPadding: "20px",
        focusOnSelect: true,
        infinite: true,
        speed: 100,
        slidesToShow: 4,
        slidesToScroll: 1,
        touchThreshold: 5,
        arrows: true,
        responsive: [
          {
            breakpoint: 1300,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 900,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 750,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
            },
          },
        ],
      },
    };
  },
};
</script>

<style lang="scss">
@media screen and (max-width: 1440px) {
  .slider {
    max-width: 1200px !important;
  }
}
@media screen and (max-width: 1300px) {
  .slider {
    max-width: 1100px !important;
  }
}
@media screen and (max-width: 1200px) {
  .slider {
    max-width: 1000px !important;
  }
}
@media screen and (max-width: 1100px) {
  .slider {
    max-width: 950px !important;
  }
}
@media screen and (max-width: 1000px) {
  .slider {
    max-width: 880px !important;
  }
}
@media screen and (max-width: 900px) {
  .slider {
    max-width: 800px !important;
  }
}
@media screen and (max-width: 850px) {
  .slider {
    max-width: 700px !important;
  }
}
@media screen and (max-width: 750px) {
  .slider {
    max-width: 600px !important;
    padding: 25px 100px !important;
  }
}
@media screen and (max-width: 650px) {
  .slider {
    max-width: 500px !important;
    padding: 25px 100px !important;
  }
}
@media screen and (max-width: 600px) {
  .slider {
    max-width: 500px !important;
    padding: 25px 100px !important;
  }
}
</style>
