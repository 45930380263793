<template>
  <div class="banner-wrapper" @mousemove="animateBannerFtuits">
<!--    <div class="b-fresh_animation">-->
<!--      <div class="b-fresh__image__item_1"></div>-->
<!--      <div class="b-fresh__image__item_2"></div>-->
<!--      <div class="b-fresh__image__item_3"></div>-->
<!--    </div>-->
    <div class="banner-responsive">
      <img src="../../assets/bFresh-img/three-gap.png" />
    </div>
    <div class="banner">
      <div id="anor-1" class="anor meva_cheva_banner" data-speed="2">
        <img src="../../assets/bFresh-img/anor.webp" />
      </div>
      <div id="anor-2" class="anor meva_cheva_banner" data-speed="2">
        <img src="../../assets/bFresh-img/anor.webp" />
      </div>
      <div id="anor-3" class="anor meva_cheva_banner" data-speed="3">
        <img src="../../assets/bFresh-img/anor.webp" />
      </div>
      <div id="qulpnay-1" class="meva_cheva_banner" data-speed="1">
        <img src="../../assets/bFresh-img/qulpnay-1.webp" />
      </div>
      <div id="qulpnay-2" class="meva_cheva_banner" data-speed="2">
        <img src="../../assets/bFresh-img/qulpnay-2.webp" />
      </div>
      <div id="qulpnay-3" class="meva_cheva_banner" data-speed="2">
        <img src="../../assets/bFresh-img/qulpnay-3.webp" />
      </div>
      <div id="qulpnay-4" class="meva_cheva_banner" data-speed="2">
        <img src="../../assets/bFresh-img/qulpnay-2.webp" />
      </div>
      <div id="limon-1" class="meva_cheva_banner" data-speed="2">
        <img src="../../assets/bFresh-img/limon.webp" />
      </div>
      <div id="limon-2" class="meva_cheva_banner" data-speed="2">
        <img src="../../assets/bFresh-img/limon.webp" />
      </div>
      <div class="banner__bg">
        <img src="../../assets/bFresh-img/bfresh-banner.webp" />
        <div class="fresh">
          <img src="../../assets/bFresh-img/be-fresh.png" />
        </div>
      </div>
    </div>
    <div class="banner-wrapper__bottom"></div>
  </div>
</template>

<script>
// import { ScrollTrigger } from "gsap/ScrollTrigger";
export default {
  methods: {
    animateBannerFtuits(e) {
      document.querySelectorAll(".meva_cheva_banner").forEach((el) => {
        const speed = parseInt(el.getAttribute("data-speed"));
        const x = (window.innerWidth - e.pageX * speed) / 20;
        const y = (window.innerHeight - e.pageY * speed) / 20;
        el.style.transform = `translateX(${x}px) translateY(${y}px)`;
      });
    },
  },
  mounted() {
    // gsap.registerPlugin(ScrollTrigger);
    // gsap.from(".banner__text", {
    //   scrollTrigger: {
    //     trigger: ".banner",
    //     toggleActions: "resume resume none resume",
    //     start: "-50px top",
    //     end: "bottom top",
    //     // markers: true
    //   },
    //   y: 200,
    //   opacity: 0,
    //   duration: 2,
    //   ease: "bounce.out",
    // });
    //
    // // gsap.registerPlugin(ScrollTrigger)
    // let image1 = gsap.timeline();
    // let image2 = gsap.timeline();
    // let image3 = gsap.timeline();
    //
    // image1.from(".b-fresh__image__item_1", {
    //   scrollTrigger: {
    //     trigger: ".b-fresh_animation",
    //     toggleActions: "restart none reverse none",
    //     start: "-150px",
    //     end: "-100px",
    //     // markers: true
    //   },
    //   x: -500,
    //   y: -500,
    //   opacity: 0,
    //   scale: 0,
    //   rotation: -30,
    //   duration: 2,
    // });
    // image2.from(".b-fresh__image__item_2", {
    //   scrollTrigger: {
    //     trigger: ".b-fresh_animation",
    //     toggleActions: "restart none reverse none",
    //     start: "-150px",
    //     end: "-100px",
    //   },
    //   x: 500,
    //   y: -500,
    //   opacity: 0,
    //   scale: 0,
    //   rotation: 30,
    //   duration: 2,
    // });
    // image3.from(".b-fresh__image__item_3", {
    //   scrollTrigger: {
    //     trigger: ".b-fresh_animation",
    //     toggleActions: "restart none reverse none",
    //     start: "-150px",
    //     end: "-100px",
    //   },
    //   x: 0,
    //   y: -500,
    //   opacity: 0,
    //   scale: 0,
    //   // rotation: 30,
    //   duration: 2,
    // });
    //
    // image1.to(".b-fresh__image__item_1", {
    //   scrollTrigger: {
    //     trigger: ".b-fresh_animation",
    //     toggleActions: "restart none reverse none",
    //     start: "-100px top",
    //     end: "600px top",
    //     // markers: true,
    //     scrub: true,
    //   },
    //   ease: "steps(73)",
    //   xPercent: -500,
    //   rotation: 0,
    //   backgroundPosition: -189 * 73 + "px 50%",
    // });
    // image2.to(".b-fresh__image__item_2", {
    //   scrollTrigger: {
    //     trigger: ".b-fresh_animation",
    //     toggleActions: "restart none reverse none",
    //     start: "-100px top",
    //     end: "600px top",
    //     // markers: true,
    //     // pin: true,
    //     scrub: true,
    //   },
    //   ease: "steps(73)",
    //   xPercent: 500,
    //   rotation: 0,
    //   backgroundPosition: -189 * 73 + "px 50%",
    //   // ease: "steps(73)",
    //   // rotation: 0,
    //   // xPercent: 50,
    //   // backgroundPosition: -189 * 73 + "px 50%",
    // });
    // image3.to(".b-fresh__image__item_3", {
    //   scrollTrigger: {
    //     trigger: ".b-fresh_animation",
    //     toggleActions: "restart none reverse none",
    //     start: "-100px top",
    //     end: "1200px top",
    //     // markers: true,
    //     pin: true,
    //     scrub: true,
    //   },
    //   ease: "steps(73)",
    //   // xPercent: 500,
    //   // scale: 2,
    //   rotation: 0,
    //   backgroundPosition: -189 * 73 + "px 50%",
    //   // ease: "steps(73)",
    //   // rotation: 0,
    //   // xPercent: 50,
    //   // backgroundPosition: -189 * 73 + "px 50%",
    // });
  },
};
</script>

<style lang="scss">
.b-fresh_animation {
  width: 1600px !important;
  position: absolute;
  height: 700px;
  //background-color: red;
  z-index: 9;
  display: flex;
  justify-content: center;
  .b-fresh__image__item_1 {
    width: 189px;
    height: 475px;
    //color: #42b983;
    background-image: url("${process.env.BASE_URL}line_red_72.webp");
    //background-image: url("${process.env.BASE_URL}line_dark_blue_72.webp");
    background-repeat: no-repeat;
    background-position: 0px 50%;
    position: absolute;
    transform: rotate(-30deg) scale(1) translate(-250px, 0px);
  }
  .b-fresh__image__item_2 {
    width: 189px;
    height: 475px;
    //color: #42b983;
    background-image: url("${process.env.BASE_URL}pomegranate.webp");
    //background-image: url("${process.env.BASE_URL}line_red_72.webp");
    background-repeat: no-repeat;
    background-position: 0px 50%;
    position: absolute;
    transform: rotate(30deg) scale(1) translate(250px, 0px);
  }
  .b-fresh__image__item_3 {
    width: 189px;
    height: 475px;
    //color: #42b983;
    background-image: url("${process.env.BASE_URL}line_dark_blue_72.webp");
    background-repeat: no-repeat;
    background-position: 0px 50%;
    position: absolute;
    transform: rotate(0deg) scale(1) translate(0px, 50px);
  }
}
.anor {
  width: 114px;
  height: 127px;
  img {
    width: 100%;
  }
}
</style>
