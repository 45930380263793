<template>
  <div class="three-limonade">
    <div class="three-limonade__text">
      <h3>{{ $t("section7.title4") }}</h3>
      <p>
        {{ $t("section7.title5") }}
      </p>
    </div>
    <div class="three-limonade__img">
      <img src="../../assets/bFresh-img/three-can.png" />
    </div>
  </div>
</template>

<script>
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
export default {
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
    gsap.from(".three-limonade__text", {
      scrollTrigger: {
        trigger: ".three-limonade",
        toggleActions: "resume resume none resume",
        start: "-500px top",
        end: "bottom top",
        // markers: true
      },
      y: 100,
      opacity: 0,
      duration: 2,
      ease: "bounce.out",
    });
    gsap.from(".three-limonade__img", {
      scrollTrigger: {
        trigger: ".three-limonade",
        toggleActions: "resume resume none resume",
        start: "-500px top",
        end: "bottom top",
        // markers: true
      },
      y: 100,
      opacity: 0,
      delay: 0.5,
      duration: 2,
      ease: "bounce.out",
    });
  },
};
</script>

<style></style>
