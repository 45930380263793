<template>
  <div class="featured-events">
    <div class="featured-events__img">
      <img :src="$img + 'featured-left.webp'" />
    </div>
    <div>
      <h3>Featured Events</h3>
      <p>Most Lit Events Going On Now!</p>
    </div>
    <div>
      <img class="featured-events__img" :src="$img + 'featured-rigth.webp'" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
