<template>
  <div class="main">
    <Banner />
    <buy-energy></buy-energy>
    <Golf />
    <three-limonade></three-limonade>
    <Slick />
    <div class="footer-bg">
      <the-footer></the-footer>
    </div>
  </div>
</template>

<script>
import Banner from "../../components/b-fresh/Banner.vue";
import BuyEnergy from "../../components/b-fresh/BuyEnergy.vue";
import FeatureEvents from "../../components/b-fresh/FeaturedEvents.vue";
import Golf from "../../components/b-fresh/Golf.vue";
import ThreeLimonade from "../../components/b-fresh/ThreeLimonade.vue";
import Video from "../../components/b-fresh/Video.vue";
import Slick from "../../components/b-fresh/Slick.vue";
import TheFooter from "../../components/b-fresh/TheFooter.vue";
import loader from "../loader";
export default {
  components: {
    Banner,
    Golf,
    Video,
    Slick,
    loader,
    "buy-energy": BuyEnergy,
    "feature-events": FeatureEvents,
    "three-limonade": ThreeLimonade,
    "the-footer": TheFooter,
  },
  data() {
    return {
      onload: true,
    };
  },
  methods: {
    // stopLoad(e) {
    //   console.log("B-Fresh Loaded", e);
    //   this.$store.commit("SET_BFRESH_LOADER", false);
    //   this.$router.push({
    //     name: this.$route.name,
    //     query: {
    //       load: false,
    //     },
    //   });
    // },
  }
  // created() {
  //   if (!this.$route.query.load) {
  //     window.onload = this.stopLoad;
  //   } else {
  //     this.$store.commit("SET_BFRESH_LOADER", false);
  //   }
  // },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
