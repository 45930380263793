<template>
  <div class="three-limonade golf" @mousemove="animateBannerFtuits">
    <div class="golf__list">
      <div id="list-7" class="golf_item" data-speed="3">
        <img src="../../assets/bFresh-img/list-7.png" />
      </div>
    </div>
    <div class="golf__big-b">
      <img src="../../assets/bFresh-img/big-b.png" />
    </div>
    <div class="three-limonade__text">
      <h3>{{ $t("section7.title8") }}</h3>
      <p>
        {{ $t("section7.title9") }}
      </p>
    </div>
    <div
      class="three-limonade__img golf_item"
      data-speed="1"
      style="z-index: 10"
    >
      <img src="../../assets/bFresh-img/golf.png" />
    </div>
  </div>
</template>

<script>
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
export default {
  methods: {
    animateBannerFtuits(e) {
      document.querySelectorAll(".golf_item").forEach((el) => {
        const speed = parseInt(el.getAttribute("data-speed"));
        const x = (window.innerWidth - e.pageX * speed) / 20;
        // const y = (window.innerHeight - e.pageY * speed) / 50
        el.style.transform = `translateX(${x}px)`;
      });
    },
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
    gsap.from(".golf__text", {
      scrollTrigger: {
        trigger: ".golf",
        toggleActions: "resume resume none resume",
        start: "top top",
        end: "bottom top",
        // markers: true
      },
      y: 100,
      opacity: 0,
      duration: 2,
      ease: "bounce.out",
    });
  },
};
</script>
