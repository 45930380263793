<template>
  <div class="buy-energy" @mousemove="animateBannerFtuits">
    <div class="buy-energy__bg">
      <img src="../../assets/bFresh-img/volna.webp" />
    </div>
    <div style="z-index: 10" class="buy-energy__title">
      <h1 style="z-index: 10">{{ $t("section7.title1") }}</h1>
    </div>
    <div class="buy-energy__lists">
      <!-- <div id="list-1" class="meva_cheva_buy_energy" data-speed="2">
        <img :src="$img + 'list-2.webp'" />
      </div> -->
      <div id="list-2" class="meva_cheva_buy_energy" data-speed="1">
        <img src="../../assets/images/list-2.png" />
      </div>
      <div id="list-3" class="meva_cheva_buy_energy" data-speed="2">
        <img :src="$img + 'list-3.webp'" />
      </div>
      <div id="list-4" class="meva_cheva_buy_energy" data-speed="1">
        <img src="../../assets/images/list-4.png" />
      </div>
      <div id="list-5" class="meva_cheva_buy_energy" data-speed="2">
        <img src="../../assets/images/list-5.png" />
      </div>
      <div id="list-6" class="meva_cheva_buy_energy" data-speed="1">
        <img :src="$img + 'list-6.webp'" />
      </div>
      <div id="limon-3" class="meva_cheva_buy_energy" data-speed="2">
        <img src="../../assets/bFresh-img/qulpnay-2.webp" />
      </div>
      <div id="limon-4" class="meva_cheva_buy_energy" data-speed="1">
        <img src="../../assets/bFresh-img/qulpnay-2.webp" />
      </div>
      <div id="limon-5" class="meva_cheva_buy_energy" data-speed="2">
        <img src="../../assets/bFresh-img/qulpnay-2.webp" />
      </div>
      <div id="limon-6" class="meva_cheva_buy_energy" data-speed="1">
        <img src="../../assets/bFresh-img/qulpnay-2.webp" />
      </div>
    </div>
    <div id="fresh-product" class="buy-energy__grid">
      <div class="buy-energy__animation">
        <VueSlickCarousel
          style="width: 190px; height: 497px"
          v-bind="settings"
          @afterChange="afterChangeSliderFresh"
        >
          <div class="three3dlemon">
            <div id="threesixtylemon"></div>
          </div>
          <div class="three3dstrawberry">
            <div id="threesixtystrawberry"></div>
          </div>
          <div class="three3dpomegranate">
            <div id="threesixtypomegranate"></div>
          </div>
        </VueSlickCarousel>
        <div class="bFresh-360">
          <img src="../../assets/images/360.webp" />
        </div>
      </div>
      <div class="buy-energy__payment">
        <div
          style="transform: translate(0, 0) !important"
          v-if="isFreshSlide == false"
          class="buy-energy__payment-title"
        >
          <h4>{{ $t("section8.title1") }}</h4>
          <p style="font-weight: initial">
            {{ $t("section8.text1") }}
          </p>
          <div class="buy-energy__payment-size">
            <div class="energy-450">
              <div class="energy-450__image">
                <img src="../../assets/bFresh-img/energy-450.png" />
              </div>
              <span class="energy-450__text">450 {{ $t("litr") }}</span>
            </div>
          </div>
          <a href="#footer">
            <button class="buy-energy__payment-button">{{ $t("buy") }}</button>
          </a>
        </div>
        <div
          style="transform: translate(0, 0) !important"
          v-else-if="isFreshSlide == true"
          class="buy-energy__payment-title"
        >
          <h4>{{ $t("section8.title2") }}</h4>
          <p style="font-weight: initial">
            {{ $t("section8.text2") }} <br /><br />
          </p>

          <div class="buy-energy__payment-size">
            <div class="energy-450">
              <div class="energy-450__image">
                <img src="../../assets/bFresh-img/energy-450.png" />
              </div>
              <span class="energy-450__text">450 {{ $t("litr") }}</span>
            </div>
          </div>
          <a href="#footer">
            <button class="buy-energy__payment-button">{{ $t("buy") }}</button>
          </a>
        </div>
        <div
          style="transform: translate(0, 0) !important"
          v-else-if="isFreshSlide == null"
          class="buy-energy__payment-title"
        >
          <h4>{{ $t("section8.title3") }}</h4>
          <p style="font-weight: initial">
            {{ $t("section8.text3") }} <br /><br />
          </p>

          <div class="buy-energy__payment-size">
            <div class="energy-450">
              <div class="energy-450__image">
                <img src="../../assets/bFresh-img/energy-450.png" />
              </div>
              <span class="energy-450__text">450 {{ $t("litr") }}</span>
            </div>
          </div>
          <a href="#footer">
            <button class="buy-energy__payment-button">{{ $t("buy") }}</button>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import viewer from "../viewer";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ThreeSixty from "@mladenilic/threesixty.js";
export default {
  components: {
    viewer,
    VueSlickCarousel,
  },
  data() {
    return {
      images: {
        imgLemon: [],
        imgStrawberry: [],
        imgPomegranate: [],
      },
      settings: {
        arrows: true,
        dots: false,
        // "fade": true,
        infinite: false,
        speed: 500,
        swipe: false,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
      isFreshSlide: false,
    };
  },
  methods: {
    afterChangeSliderFresh(e) {
      if (e == 0) {
        this.isFreshSlide = false;
      } else if (e == 1) {
        this.isFreshSlide = true;
      } else if (e == 2) {
        this.isFreshSlide = null;
      }
    },
    animateBannerFtuits(e) {
      document.querySelectorAll(".meva_cheva_buy_energy").forEach((el) => {
        const speed = parseInt(el.getAttribute("data-speed"));
        const x = (window.innerWidth - e.pageX * speed) / 20;
        const y = (window.innerHeight - e.pageY * speed) / 20;
        el.style.transform = `translateX(${x}px) translateY(${y}px)`;
      });
    },
  },
  mounted() {
    for (let i = 99; i < 172; i++) {
      this.images.imgLemon.push(
        `${process.env.BASE_URL}rotate/befresh-lemon/${i}.png`
      );
    }
    for (let i = 100; i < 173; i++) {
      this.images.imgStrawberry.push(
        `${process.env.BASE_URL}rotate/befresh-strawberry/${i}.png`
      );
    }
    for (let i = 100; i < 173; i++) {
      this.images.imgPomegranate.push(
        `${process.env.BASE_URL}rotate/befresh-pomegranate/${i}.png`
      );
    }
    new ThreeSixty(document.getElementById("threesixtylemon"), {
      image: this.images.imgLemon,
      count: 72,
      width: 190,
      height: 500,
      draggable: true,
      dragTolerance: 1,
      keys: true,
      inverted: true,
    });
    new ThreeSixty(document.getElementById("threesixtystrawberry"), {
      image: this.images.imgStrawberry,
      count: 72,
      width: 190,
      height: 500,
      draggable: true,
      dragTolerance: 1,
      keys: true,
      inverted: true,
    });
    new ThreeSixty(document.getElementById("threesixtypomegranate"), {
      image: this.images.imgPomegranate,
      count: 72,
      width: 190,
      height: 500,
      draggable: true,
      dragTolerance: 1,
      keys: true,
      inverted: true,
    });

    gsap.registerPlugin(ScrollTrigger);
    gsap.from(".buy-energy__payment-title", {
      scrollTrigger: {
        trigger: ".buy-energy",
        toggleActions: "resume resume none resume",
        start: "-200px top",
        end: "bottom top",
        // markers: true
      },
      y: 100,
      opacity: 0,
      duration: 2,
      ease: "bounce.out",
    });
    gsap.from(".buy-energy__title", {
      scrollTrigger: {
        trigger: ".buy-energy",
        toggleActions: "resume resume none resume",
        start: "-600px top",
        end: "center top",
        // markers: true
      },
      y: 100,
      opacity: 0,
      duration: 2,
      ease: "bounce.out",
    });
  },
};
</script>

<style>
.three3dpomegranate,
.three3dstrawberry,
.three3dlemon {
  position: relative;
  z-index: 99999 !important;
}
</style>
