<template>
  <div id="fresh-video" class="video" @mousemove="animateBannerFtuits">
<!--    <div>-->
<!--      <div class="video_animation__image_1"></div>-->
<!--      <div class="video_animation__image_2"></div>-->
<!--    </div>-->
    <!--    <div class="video_animation">-->

    <!--    </div>-->
    <div class="video__lists">
      <div id="list-8" class="video_leafs" data-speed="1">
        <img :src="$img + 'list-8.webp'" />
      </div>
      <div id="list-9" class="video_leafs" data-speed="1">
        <img src="../../assets/images/list-5.png" />
      </div>
      <div id="list-10" class="video_leafs" data-speed="1">
        <img src="../../assets/images/list-4.png" />
      </div>
      <div id="list-11" class="video_leafs" data-speed="1">
        <img src="../../assets/images/list-5.png" />
      </div>
    </div>
    <iframe
      class="video-item"
      width="100%"
      height="550"
      loading="lazy"
      src="https://www.youtube.com/embed/ZRh4F4TLFBg"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>
</template>

<script>
// import gsap from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";
export default {
  methods: {
    animateBannerFtuits(e) {
      document.querySelectorAll(".video_leafs").forEach((el) => {
        const speed = parseInt(el.getAttribute("data-speed"));
        const x = (window.innerWidth - e.pageX * speed) / 20;
        // const y = (window.innerHeight - e.pageY * speed) / 50
        el.style.transform = `translateX(${x}px)`;
      });
    },
  },
  mounted() {
    // gsap.registerPlugin(ScrollTrigger);
    // const img1 = gsap.timeline();
    // const img2 = gsap.timeline();
    //
    // img1.from(".video_animation__image_1", {
    //   scrollTrigger: {
    //     trigger: ".video",
    //     toggleActions: "restart none reverse none",
    //     start: "-100px",
    //     end: "0px",
    //     // markers: true,
    //     scrub: true,
    //   },
    //   opacity: 0,
    //   x: -200,
    //   // scale: 0,
    //   // rotation: -30,
    //   duration: 3,
    // });
    // img2.from(".video_animation__image_2", {
    //   scrollTrigger: {
    //     trigger: ".video",
    //     toggleActions: "restart none reverse none",
    //     start: "-100px",
    //     end: "0px",
    //     scrub: true,
    //   },
    //   opacity: 0,
    //   x: 200,
    //   // scale: 0,
    //   // rotation: -30,
    //   duration: 3,
    // });
    //
    // img1.to(".video_animation__image_1", {
    //   scrollTrigger: {
    //     trigger: ".video",
    //     toggleActions: "restart none reverse none",
    //     start: "top top",
    //     end: "1200px top",
    //     // markers: true,
    //     scrub: true,
    //   },
    //   ease: "steps(72)",
    //   // xPercent: -500,
    //   // rotation: 0,
    //   backgroundPosition: -189 * 72 + "px 50%",
    // });
    // img2.to(".video_animation__image_2", {
    //   scrollTrigger: {
    //     trigger: ".video",
    //     toggleActions: "restart none reverse none",
    //     start: "top top",
    //     end: "1200px top",
    //     // markers: true,
    //     pin: true,
    //     scrub: true,
    //   },
    //   ease: "steps(72)",
    //   // rotation: 0,
    //   // xPercent: 50,
    //   backgroundPosition: -189 * 72 + "px 50%",
    // });
  },
};
</script>

<style lang="scss">
.video_animation__image_1 {
  width: 190px;
  height: 497px;
  position: absolute;
  top: 15%;
  left: 1%;
  //opacity: 0;
  transform: translateX(-80px) scale(1.2);
  //z-index: 10;
  background-image: url("${process.env.BASE_URL}line_dark_blue_72.webp");
  background-repeat: no-repeat;
  background-position: 0px 50%;
}
.video_animation__image_2 {
  width: 190px;
  height: 497px;
  position: absolute;
  top: 15%;
  right: 1%;
  //opacity: 0;
  transform: translateX(80px) scale(1.2);
  //z-index: 10;
  background-image: url("${process.env.BASE_URL}line_red_72.webp");
  background-repeat: no-repeat;
  background-position: 0px 50%;
  //background-image: url("${process.env.BASE_URL}red_01.webp");
}
</style>
