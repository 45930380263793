<template>
  <div class="loader">
    <div class="logo_loader">
      <img src="../assets/images/loader-logo.png" alt="Logo"/>
    </div>
    <div class="lds-spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "loader",
};
</script>

<style lang="scss">
.lds-spinner {
  color: white;
  margin-top: 100px;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #fff;
}

.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}

.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}

.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}

.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}

.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.loader {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000000;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  z-index: 999999999;

  h1 {
    margin: 0;
    padding: 0;
    /*font-family: ‘Arial Narrow’, sans-serif;*/
    font-family: "Berlin Sans FB";
    font-weight: 400;
    font-size: 32px;
    /*font-size: 72px;*/
    /*background: -webkit-linear-gradient(#eee, #333);*/
    /*-webkit-background-clip: text;*/
    /*-webkit-text-fill-color: transparent;*/
    /*background: linear-gradient(to bottom, #FFEBBE, #E7A408);*/
    /*-webkit-text-fill-color: transparent;*/
    /*background-clip: border-box;*/
    color: #f8981c;
    transform: translateY(-100px);
  }

  span {
    position: relative;
    top: 0.63em;
    margin: 0 5px;
    display: inline-block;
    text-transform: uppercase;
    opacity: 0;
    transform: rotateX(-90deg);
  }

  .let1 {
    animation: drop 1.2s ease-in-out infinite;
    animation-delay: 1.2s;
  }

  .let2 {
    animation: drop 1.2s ease-in-out infinite;
    animation-delay: 1.3s;
  }

  .let3 {
    animation: drop 1.2s ease-in-out infinite;
    animation-delay: 1.4s;
  }

  .let4 {
    animation: drop 1.2s ease-in-out infinite;
    animation-delay: 1.5s;
  }

  .let5 {
    animation: drop 1.2s ease-in-out infinite;
    animation-delay: 1.6s;
  }

  .let6 {
    animation: drop 1.2s ease-in-out infinite;
    animation-delay: 1.7s;
  }

  .let7 {
    animation: drop 1.2s ease-in-out infinite;
    animation-delay: 1.8s;
  }

  @keyframes drop {
    10% {
      opacity: 0.5;
    }
    20% {
      opacity: 1;
      top: 3.78em;
      transform: rotateX(-360deg);
    }
    80% {
      opacity: 1;
      top: 3.78em;
      transform: rotateX(-360deg);
    }
    90% {
      opacity: 0.5;
    }
    100% {
      opacity: 0;
      top: 6.94em;
    }
  }
}

.logo_loader {
  width: 100px;
  height: 120px;
  position: relative;
  z-index: 999;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
