<template>
  <div class="wrapper-about">
    <div class="about" :class="menuOpen ? 'overflow-hidden' : '' ">
      <the-header></the-header>
      <Main />
    </div>
  </div>
</template>

<script>
import TheHeader from '../components/b-fresh/TheHeader.vue'
// import TheFooter from '../components/b-fresh/TheFooter.vue'
import Main from '../components/b-fresh/Main.vue'

export default {
  data() {
    return {
      menuOpen: false,
    };
  },
  components: {
    'the-header': TheHeader,
    // 'the-footer': TheFooter,
    Main

  }
}
</script>

<style lang="scss">
.overflow-hidden {
  overflow: hidden !important;
}
</style>