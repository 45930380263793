<template>
  <div>
    <div id="top" class="header">
      <div class="header__logo">
        <img src="../../assets/bFresh-img/bfresh-logo.png"/>
      </div>
      <ul class="header__menu">
        <li>BE FRESH</li>
        <span></span>
        <li @click="goOu">OU7 Energy</li>
        <span></span>
        <a href="#fresh-product">
          <li>{{ $t('product') }}</li>
        </a>
        <span></span>
        <a href="#footer">
          <li>{{ $t('contact') }}</li>
        </a>
      </ul>
      <ul class="header__lang">
        <li v-if="$i18n.locale === 'uz' || $i18n.locale === 'ru'" @click="setLang('en')">EN</li>
        <span v-if="$i18n.locale === 'uz' || $i18n.locale === 'ru'"></span>
        <li v-if="$i18n.locale === 'en' || $i18n.locale === 'ru'" @click="setLang('uz')">UZ</li>
        <span v-if="$i18n.locale === 'en'"></span>
        <li v-if="$i18n.locale === 'uz' || $i18n.locale === 'en'" @click="setLang('ru')">RU</li>
      </ul>
      <div class="menu-btn" :class="menuOpen ? 'open' : ''" @click="clickMenu">
        <div class="menu-btn__burger"></div>
      </div>
      <transition name="fade">
        <div
          class="responsive-menu"
          :class="menuOpen ? 'responsive-menu_block' : ''"
        >
          <ul
            class="responsive-menu__lists"
            :class="menuOpen ? 'responsive-menu__lists_block' : ''"
          >
            <li @click="menuOpen = false">BE-FRESH</li>
            <li @click="goOu">OU7 Energy</li>
            <a @click="menuOpen = false" href="#fresh-product">
              <li>{{ $t('product') }}</li>
            </a>
            <a @click="menuOpen = false" href="#footer">
              <li>{{ $t('contact') }}</li>
            </a>
          </ul>
          <ul
            class="header__lang"
            :class="menuOpen ? 'header__lang_block' : ''"
          >
            <li v-if="$i18n.locale === 'uz' || $i18n.locale === 'ru'" @click="setLang('en')">EN</li>
            <span v-if="$i18n.locale === 'uz' || $i18n.locale === 'ru'"></span>
            <li v-if="$i18n.locale === 'en' || $i18n.locale === 'ru'" @click="setLang('uz')">UZ</li>
            <span v-if="$i18n.locale === 'en'"></span>
            <li v-if="$i18n.locale === 'uz' || $i18n.locale === 'en'" @click="setLang('ru')">RU</li>
          </ul>
        </div>
      </transition>
    </div>
    <div class="header-bottom"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuOpen: false,
    };
  },
  methods: {
    clickMenu() {
      this.menuOpen = !this.menuOpen;
    },
    setLang(lang) {
      localStorage.setItem('lang', lang)
      this.$i18n.locale = lang
    },
    goOu() {
      this.$router
        .push({
          name: "Home",
        })
    },
  },
};
</script>

<style lang="scss">
.about {
  .menu-btn {
    width: 80px;
    height: 80px;
    position: relative;
    display: none;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    /* border:3px solid #fff; */
  }
  .menu-btn__burger {
    width: 40px;
    height: 4px;
    background: #fff;
    border-radius: 5px;
    transition: all 0.2s ease-in-out;
  }
  .menu-btn__burger::before,
  .menu-btn__burger::after {
    content: "";
    position: absolute;
    width: 40px;
    height: 4px;
    background: #fff;
    border-radius: 5px;
    transition: all 0.2s ease-in-out;
  }
  .menu-btn__burger::before {
    transform: translateY(-10px);
  }
  .menu-btn__burger::after {
    transform: translateY(10px);
  }

  .menu-btn.open .menu-btn__burger {
    color: transparent;
    background: transparent;
  }

  .menu-btn.open .menu-btn__burger::before {
    transform: rotate(45deg) translate(0px, 0px);
    background: #fff;
  }
  .menu-btn.open .menu-btn__burger::after {
    transform: rotate(-45deg) translate(0px, 0px);
    background: #fff;
  }
  .responsive-menu_block {
    display: block !important;
  }
  .responsive-menu {
    display: none;
    position: fixed;
    top: 70px;
    left: 0;
    right: 0;
    bottom: 0;
    background: #1f959e;
    z-index: 100;
    &__lists_block {
      display: block !important;
      transition: 1s;
      li {
        color: #fff !important;
      }
    }
    &__lists {
      display: none;
      position: absolute;
      top: 0px;
      left: 56%;
      transform: translate(-50%, 0);
      li {
        font-family: Roboto;
        font-style: italic;
        font-weight: 500;
        font-size: 22px;
        line-height: 23px;
        letter-spacing: 5px;
        color: #000;
        list-style: none;
        margin: 50px 0;
        cursor: pointer;
        &:active {
          color: #fff;
        }
        &:hover {
          color: #fff;
        }
      }
    }
    .header__lang_block {
      display: flex !important;
    }
    .header__lang {
      transition: all 1s;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 400px);
      li {
        color: #fff;
      }
    }
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
  @media screen and (max-width: 992px) {
    .menu-btn {
      display: flex;
    }
    .header__menu {
      display: none !important;
    }
    .header__lang {
      display: none !important;
    }
  }
}
</style>
