<template>
  <div id="footer" class="footer">
    <h3 class="footer__title">{{ $t("section7.title6") }}</h3>
    <p class="footer__paragraph">{{ $t("section7.title7") }}</p>
    <div class="footer__social-media social-media">
      <div>
        <a class="center-img" href="https://ou7energy.com"
          ><img src="../../assets/ou7-img/facebook.png" alt="Logo Facebook"
        /></a>
      </div>
      <div>
        <a class="center-img" href="https://ou7energy.com"
          ><img src="../../assets/ou7-img/instagram.png" alt="Logo Instagram"
        /></a>
      </div>
      <div>
        <a class="center-img" href="https://ou7energy.com"
          ><img src="../../assets/ou7-img/telegram.png" alt="Logo Telegram"
        /></a>
      </div>
    </div>
    <p style="width: 100%" class="paragraph">{{ $t("footer.phone") }}</p>
    <br>
    <p>{{ $t("footer.copyright") }}</p>
    <div class="footer__top">
      <a href="#top" style="display: inline-block;z-index: 9999;position: relative">
        <img src="../../assets/bFresh-img/top.webp" />
      </a>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.center-img {
  display: flex;
  justify-content: center;
  align-items: center;
}
.about {
  .footer {
    &__title {
      font-family: Roboto;
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 50px;
      color: #fff;
      text-align: center;
    }
    &__paragraph {
      font-family: Roboto;
      font-style: italic;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #fff;
      margin-top: 20px;
    }
    position: relative;
    .footer__top {
      width: 50px;
      position: absolute;
      bottom: 50px;
      right: 30px;
      img {
        width: 100%;
      }
    }
  }
}
</style>
